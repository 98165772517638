import React, {useEffect} from 'react';
import { Col, Row } from 'antd';
import { Snippet } from '../code-snippet';
import { useGetIdentity, useOne } from "@refinedev/core";

type SnippetInterface = {
    code: string,
}

export const Installation = () => {
    // Getting client key
    const { data: user } = useGetIdentity<{
        user_websites: { selected: string, clientKey: string }[]
    }>();

    useEffect(() => {
        document.title = "Installation | WebAccessify";
    }, []);

    // Getting the client key of the selected website
    const clientKey = user?.user_websites?.find((website) => website.selected === 'true')?.clientKey;

    const code = `<script>!function(){var e=document.createElement("script");e.src="https://manage.webaccessify.com/wafy/script.js?clientKey=${clientKey}",document.head.appendChild(e)}();</script>`;

    return (
        <div style={{ margin: '0 auto' }}>
            <div className='card' style={{ backgroundColor: '#fcfcfc', borderRadius: '15px' }}>
                <Col>
                    <Row>
                        <h2 style={{ padding: '20px 20px', color: '#000' }}> Widget Embedded Script</h2>
                    </Row >
                    <Row>
                        <h3 style={{ padding: '0 20px', color: '#000' }}>Insert the following script into the head section on your website.</h3>
                    </Row>
                    <Row>
                        <div
                            style={{
                                marginTop: '3%',
                                backgroundColor: '#d0eeed',
                                margin: '2%',
                                borderRadius: '10px',
                                padding: '20px',
                                color: '#000',
                                maxHeight: '200px', 
                                overflowY: 'auto',
                            }}
                            id="colored-text"
                        ><Snippet code={code} />
                        </div>
                    </Row>
                </Col>
            </div>
        </div>
    )
};
