import React, {useEffect} from 'react';
import { Typography, Row, Col } from 'antd';
import { PieChart } from 'components';

const { Title } = Typography;

export const Home = () => {
  useEffect(() => {
    document.title = "Dashboard | WebAccessify";
  }, []);
  return (
    <div>
      <Title level={2} style={{ fontWeight: 700 }}>
        Dashboard
      </Title>

      <Row gutter={16}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <PieChart title="Total Webpages" value={0} series={[85, 15]} colors={['#6C5DD3', '#c4e8ef']} />
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <PieChart title="Total Users" value={0} series={[80, 20]} colors={['#7FBA7A', '#c4e8ef']} />
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <PieChart title="Total Time (Hrs)" value={0} series={[80, 20]} colors={['#FFCE73', '#c4e8ef']} />
        </Col>
      </Row>
    </div>
  );
};

