import React, {useEffect} from 'react';
import wafy from '../../assets/webaccessify-logo.png';
import { useNavigate } from 'react-router-dom';
import {
  UpdatePasswordPageProps,
  UpdatePasswordFormTypes,
  useActiveAuthProvider,
} from "@refinedev/core";
import {
  Row,
  Col,
  Layout,
  Card,
  Typography,
  Form,
  Input,
  Button,
  LayoutProps,
  CardProps,
  FormProps,
  theme,
  message,
} from "antd";
import { useTranslate, useUpdatePassword } from "@refinedev/core";

import { layoutStyles, containerStyles, titleStyles, headStyles, bodyStyles } from "styles";
import config from "config/config";
const { Title } = Typography;
const { useToken } = theme;
const API_BASE_URL = config.API_BASE_URL;

type UpdatePasswordProps = UpdatePasswordPageProps<LayoutProps, CardProps, FormProps>;

export const UpdatePassword: React.FC<UpdatePasswordProps> = ({
  wrapperProps,
  contentProps,
  renderContent,
  formProps,
  title,
}) => {
  const navigate = useNavigate();
  const { token } = useToken();
  const [form] = Form.useForm<UpdatePasswordFormTypes>();
  const translate = useTranslate();
  const authProvider = useActiveAuthProvider();
  const { mutate: updatePassword, isLoading } =
    useUpdatePassword<UpdatePasswordFormTypes>();

    useEffect(() => {
      document.title = "Update Password | WebAccessify";
    }, []);

  const handleButtonClick = async () => {
    try {
      const email = localStorage.getItem('userEmail')
      const { password, confirmPassword } = form.getFieldsValue();
      const response = await fetch(
        `${API_BASE_URL}/user/api/v1/updateForgetPassword`,
        {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, password, confirmPassword }) 
        }
    );

    const data = await response.json();

    if (response.status === 200) {
      message.success(data.message);
      navigate('/login');
    } else if (response.status === 400) {
      message.error(data.message);
    }
    } catch (error) {
      message.error("Error occurred while updating the password");
    }
  };

  const PageTitle =
    title === false ? null : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "32px",
          fontSize: "20px",
        }}
      >
        <img
          src={wafy}
          alt="WebAccessify Logo"
          style={{ height: '40px', width: '40px', marginRight: '8px' }}
        />
        <span style={{ ...titleStyles }}>
          WebAccessify
        </span>
      </div>
    );

  const CardTitle = (
    <Title
      level={3}
      style={{
        color: token.colorPrimary,
        ...titleStyles,
      }}
    >
      {translate("pages.updatePassword.title", "Set New Password")}
    </Title>
  );

  const CardContent = (
    <Card
      title={CardTitle}
      headStyle={headStyles}
      bodyStyle={bodyStyles}
      style={{
        ...containerStyles,
        backgroundColor: token.colorBgElevated,
      }}
      {...(contentProps ?? {})}
    >
      <Form<UpdatePasswordFormTypes>
        layout="vertical"
        form={form}
        requiredMark={false}
        {...formProps}
      >
        <Form.Item
          name="password"
          label={translate(
            "pages.updatePassword.fields.password",
            "New Password",
          )}
          rules={[{ required: true, message: 'Please enter your password' },
          { min: 8, message: 'Password must be at least 8 characters long' }]}
          style={{ marginBottom: "12px" }}
        >
          <Input
            type="password"
            size="middle"
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          label={translate(
            "pages.updatePassword.fields.confirmPassword",
            "Confirm New Password",
          )}
          hasFeedback
          dependencies={["password"]}
          rules={[
            {
              required: true,
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  !value ||
                  getFieldValue("password") === value
                ) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    translate(
                      "pages.updatePassword.errors.confirmPasswordNotMatch",
                      "Passwords do not match",
                    ),
                  ),
                );
              },
            }),
          ]}
        >
          <Input
            type="password"
            size="middle"
          />
        </Form.Item>
        <Form.Item
          style={{
            marginBottom: 0,
          }}
        >
          <Button
            type="primary"
            size="middle"
            htmlType="submit"
            loading={isLoading}
            style={{
              backgroundColor: token.colorPrimary
            }}
            onClick={handleButtonClick}
            block
          >
            {translate(
              "pages.updatePassword.buttons.submit",
              "Update",
            )}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );

  return (
    <Layout style={layoutStyles} {...(wrapperProps ?? {})}>
      <Row
        justify="center"
        align="middle"
        style={{
          padding: "16px 0",
          minHeight: "100dvh",
        }}
      >
        <Col xs={22}>
          {renderContent ? (
            renderContent(CardContent, PageTitle)
          ) : (
            <>
              {PageTitle}
              {CardContent}
            </>
          )}
        </Col>
      </Row>
    </Layout>
  );
};
