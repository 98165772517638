import React from 'react';
import { Typography, Row, Col } from 'antd';
import ReactApexChart from 'react-apexcharts';

const { Text } = Typography;

export const PieChart: React.FC<{
  title: string;
  value: number;
  series: number[];
  colors: string[];
}> = ({ title, value, series, colors }) => {
  return (
    <Row
      id="chart"
      align="middle"
      justify="space-between"
      style={{ backgroundColor: '#fcfcfc', padding: '12px', borderRadius: '15px', minHeight: '110px' }}
    >
      <Col flex={1}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Text style={{ fontSize: 14, color: '#808191' }}>{title}</Text>
          <Text style={{ fontSize: 24, color: '#11142d', fontWeight: 700, marginTop: 1 }}>{value}</Text>
        </div>
      </Col>
      <Col>
        <ReactApexChart
          options={{
            chart: { type: 'donut' },
            colors: colors,
            legend: { show: false },
            dataLabels: { enabled: false }
          }}
          series={series}
          type={'donut'}
          width={'120px'}
        />
      </Col>
    </Row>
  );
};

