import { useState } from "react";
import { Form, Input, Button } from "antd";

export const AddSite = () => {
    const [form] = Form.useForm();
    
    const handleFormChange = (allValues: any) => {
        const { siteUrl } = allValues;
        console.log(siteUrl);
        localStorage.setItem("siteUrl", siteUrl);
    };

    return (
        <div style={{ borderRadius: '15px', width: '100%', backgroundColor: '#fcfcfc', padding: '20px' }}>
            <Form
                form={form}
                onValuesChange={handleFormChange}
                style={{ marginTop: '20px', width: '100%', display: 'flex', flexDirection: 'column', gap: '20px' }}
            >
                <Form.Item
                    label="Enter Site Url"
                    name="siteUrl"
                    rules={[{ required: true, message: 'Please enter site URL!' }]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </div>
    );
};
