import React from "react";
import { Button, message } from "antd";

type SnippetProps = {
  [key: string]: any;
};

export const Snippet = (props: SnippetProps) => {
  const { code } = props;

  const handleCopy = () => {
    navigator.clipboard.writeText(code);
    message.success({
      content: "Code copied to clipboard!",
      className: "copy-message",
    });
  };

  return (
    <>
      <div style={{ marginBottom: "16px" }}>{code}</div>
      <Button onClick={handleCopy}>Copy Code</Button>
    </>
  );
};
