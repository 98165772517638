import { useState } from "react";
import { useOne } from "@refinedev/core";
import {
    CloseOutlined,
    EditOutlined,
    IdcardOutlined,
    MailOutlined,
    PhoneOutlined,
    SafetyCertificateOutlined,
    UserOutlined,
    HomeOutlined
} from "@ant-design/icons";
import {
    Button,
    Card,
    Drawer,
    Input,
    Space,
    Spin,
    Typography,
} from "antd";
import { CustomAvatar } from "../custom-avatar";
import { SingleElementForm } from "../single-element-form";
import { Text } from "../text";
import styles from "./index.module.css";

type Props = {
    opened: boolean;
    setOpened: (opened: boolean) => void;
    userId: string;
};


type FormKeys = "email" | "name" | "phone" | "companyName";

export const AccountSettings = ({ opened, setOpened, userId }: Props) => {
    const [activeForm, setActiveForm] = useState<FormKeys>();
    const { data, isLoading, isError } = useOne
        ({
        resource: "users",
        id: userId,
    });

    const closeModal = () => {
        setOpened(false);
    };

    if (isError) {
        closeModal();
        return null;
    }

    if (isLoading) {
        return (
            <Drawer
                open={opened}
                width={756}
                bodyStyle={{
                    background: "#f5f5f5",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Spin />
            </Drawer>
        );
    }

    const { name, email, phone, companyName } =
        data?.data;

    const getActiveForm = (key: FormKeys) => {
        if (activeForm === key) {
            return "form";
        }

        if (!data?.data[key]) {
            return "empty";
        }

        return "view";
    };

    return (
        <Drawer
            onClose={closeModal}
            open={opened}
            width={756}
            styles={{
                body: { background: "#f5f5f5", padding: 0 },
                header: { display: "none" },
            }}
        >
            <div className={styles.header}>
                <Text strong>Account Settings</Text>
                <Button
                    type="text"
                    icon={<CloseOutlined />}
                    onClick={() => closeModal()}
                />
            </div>
            <div className={styles.container}>
                <div className={styles.name}>
                    <CustomAvatar
                        style={{
                            marginRight: "1rem",
                            flexShrink: 0,
                            fontSize: "40px",
                        }}
                        size={96}
                        name={name}
                    />
                    <Typography.Title
                        level={3}
                        style={{ padding: 0, margin: 0, width: "100%" }}
                        className={styles.title}
                    >
                    {name}
                    </Typography.Title>
                </div>
                <Card
                    title={
                        <Space size={15}>
                            <UserOutlined />
                            <Text size="sm">User profile</Text>
                        </Space>
                    }
                    headStyle={{ padding: "0 12px" }}
                    bodyStyle={{ padding: "0" }}
                >
                    <SingleElementForm
                        useFormProps={{
                            id: userId,
                            resource: "users",
                        }}
                        formProps={{ initialValues: { name } }}
                        icon={<IdcardOutlined className="tertiary" />}
                        state={getActiveForm("name")}
                        itemProps={{
                            name: "name",
                            label: "Full Name",
                        }}
                        view={<Text>{name}</Text>}
                        onClick={() => setActiveForm("name")}
                        onUpdate={() => setActiveForm(undefined)}
                        onCancel={() => setActiveForm(undefined)}
                    >
                        <Input />
                    </SingleElementForm>
                    <SingleElementForm
                        useFormProps={{
                            id: userId,
                            resource: "users",
                        }}
                        formProps={{ initialValues: { phone } }}
                        icon={<PhoneOutlined className="tertiary" />}
                        state={getActiveForm("phone")}
                        itemProps={{
                            name: "phone",
                            label: "Phone",
                        }}
                        view={<Text>{phone}</Text>}
                        onClick={() => setActiveForm("phone")}
                        onUpdate={() => setActiveForm(undefined)}
                        onCancel={() => setActiveForm(undefined)}
                    >
                        <Input />
                    </SingleElementForm>
                    <SingleElementForm
                        useFormProps={{
                            id: userId,
                            resource: "users",
                        }}
                        formProps={{ initialValues: { companyName } }}
                        icon={<HomeOutlined className="tertiary" />}
                        state={getActiveForm("companyName")}
                        itemProps={{
                            name: "companyName",
                            label: "Company Name",
                        }}
                        view={<Text>{companyName}</Text>}
                        onClick={() => setActiveForm("companyName")}
                        onUpdate={() => setActiveForm(undefined)}
                        onCancel={() => setActiveForm(undefined)}
                    >
                        <Input />
                    </SingleElementForm>
                </Card>
                <Card
                    title={
                        <Space size={15}>
                            <SafetyCertificateOutlined />
                            <Text size="sm">Security</Text>
                        </Space>
                    }
                    headStyle={{ padding: "0 12px" }}
                    bodyStyle={{ padding: "0" }}
                >
                    <SingleElementForm
                        useFormProps={{
                            id: userId,
                            resource: "users",
                        }}
                        formProps={{ initialValues: { email } }}
                        icon={<MailOutlined className="tertiary" />}
                        state={getActiveForm("email")}
                        itemProps={{
                            name: "email",
                            label: "Email",
                        }}
                        view={<Text>{email}</Text>}
                        onClick={() => setActiveForm("email")}
                        onUpdate={() => setActiveForm(undefined)}
                        onCancel={() => setActiveForm(undefined)}
                    >
                        <Input />
                    </SingleElementForm>
                </Card>
            </div>
        </Drawer>
    );
};
