import React, {useEffect} from 'react';
import { WidgetCustomize } from 'pages/widget-customization';
export const Widget = () => {
    useEffect(() => {
        document.title = "Widget | WebAccessify";
    }, []);
    return (
        <>
            <WidgetCustomize />
        </>
    )
}

