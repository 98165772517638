import React, { useState, useEffect } from 'react';
import { Typography, Table, Button, Space, Modal, message } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { useGetIdentity } from "@refinedev/core";
import config from "config/config";
import { AddSite } from '../add-site';

const { Text } = Typography;
const API_BASE_URL = config.API_BASE_URL;

export const MySites = () => {
    const { data: user, refetch } = useGetIdentity<{
        user_websites: { url: string, added_at: Date, expiry_at: Date, license_type: string, license_status: String, widget_status: string }[];
    }>();

    useEffect(() => {
        document.title = "My Websites | WebAccessify";
    }, []);

    const [isModalVisible, setIsModalVisible] = useState(false);

    const capitalize = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);

    const showModal = () => { 
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleButtonClick = async () => {
        try {
            const token = localStorage.getItem("wafy-access-token");
            const siteUrl = localStorage.getItem("siteUrl");
            const response = await fetch(
                `${API_BASE_URL}/user/api/v1/addSite`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ siteUrl })
                }
            );

            const data = await response.json();
            if (response.ok) {
                message.success(data.message);
                await refetch();
            } else {
                message.error(data.message);
            }

        } catch (error) {
            message.error('Error occurred while updating email address');
        }
        finally {
            setIsModalVisible(false);
        }
    };

    const handleDelete = async (siteUrl: string) => {
        try {
            const token = localStorage.getItem("wafy-access-token");
            const response = await fetch(
                `${API_BASE_URL}/user/api/v1/deleteSite?url=${siteUrl}`,
                {
                    method: 'DELETE',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.ok) {
                message.success('Website deleted successfully');
                await refetch();
            }
        } catch (error) {
            message.error('Error occurred while deleting site');
        }
    };
    

    const columns = [
        {
            title: 'Website URL',
            dataIndex: 'url',
            key: 'url',
        },
        {
            title: 'Added At',
            dataIndex: 'added_at',
            key: 'added_at',
            render: (added_at: Date) => <Text>{new Date(added_at).toLocaleDateString()}</Text>,
        },
        {
            title: 'Expiry At',
            dataIndex: 'expiry_at',
            key: 'expiry_at',
            render: (expiry_at: Date) => <Text>{new Date(expiry_at).toLocaleDateString()}</Text>,
        },
        {
            title: 'License Type',
            dataIndex: 'license_type',
            key: 'license_type',
            render: (license_type: string) => <Text>{license_type === "free" ? 'Free Trial' : license_type}</Text>
        },
        {
            title: 'License Status',
            dataIndex: 'license_status',
            key: 'license_status',
            render: (license_status: string) => <Text>{capitalize(license_status)}</Text>
        },
        {
            title: 'Widget Status',
            dataIndex: 'widget_status',
            key: 'widget_status',
            render: (widget_status: string) => <Text>{capitalize(widget_status)}</Text>
        },
        {
            title: 'Action',
            key: 'action',
            render: (_: any, record: any) => (
                <Space size="middle">
                    <Button 
                        icon={<DeleteOutlined />} 
                        danger 
                        onClick={() => handleDelete(record.url)}
                    >
                        Delete
                    </Button>
                </Space>
            ),
        },
    ];

    const data = user?.user_websites?.map((website, index) => ({
        key: index,
        url: website.url,
        added_at: website.added_at,
        expiry_at: website.expiry_at,
        license_type: website.license_type,
        license_status: website.license_status,
        widget_status: website.widget_status
    })) || [];


    return (
        <div>
            <Space style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography.Title level={4}>My Websites</Typography.Title>
                <Button type="primary" icon={<PlusOutlined />} onClick={() => showModal()} style={{marginBottom: '10px'}}>
                    Add Site
                </Button>
            </Space>

            <Table columns={columns} dataSource={data} scroll={{ x: true }} />

            <Modal
                title={"Add Site"} 
                open={isModalVisible}
                onCancel={handleCancel}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleButtonClick}>
                        Save
                    </Button>,
                ]}
            >
                <AddSite />
            </Modal>
        </div>
    );
};