import React from "react";

import type { RefineLayoutThemedTitleProps } from "@refinedev/antd";
import { useLink } from "@refinedev/core";

import { Space, theme, Typography } from "antd";

import Logo from "../../assets/webaccessify-logo.png";

const { useToken } = theme;

const name = "WebAccessify";

export const Title: React.FC<RefineLayoutThemedTitleProps> = ({
    collapsed,
    wrapperStyles,
}) => {
    const { token } = useToken();
    const Link = useLink();

    return (
        <Link
            to="/login"
            style={{
                display: "inline-block",
                textDecoration: "none",
            }}
        >
            <Space
                style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "inherit",
                    ...wrapperStyles,
                }}
            >
               <img
                src={Logo}
                alt="WebAccessify Logo"
                style={{ width: "30px", height: "30px", marginLeft: "10px" }}
              />

                {!collapsed && (
                    <Typography.Title
                        style={{
                            fontSize: "inherit",
                            marginBottom: 0,
                            fontWeight: 700,
                        }}
                    >
                        {name}
                    </Typography.Title>
                )}
            </Space>
        </Link>
    );
};
