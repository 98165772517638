import React, { useState, useEffect } from "react";
import { Badge, Button, Divider, Popover as AntdPopover, Space, Spin, Typography } from "antd";
import { BellOutlined } from "@ant-design/icons";
import { Text } from "../text";
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from "dayjs";
import axios from 'axios';
import config from "config/config";

const API_BASE_URL = config.API_BASE_URL;

interface Notification {
    id: number;
    message: string;
    created_at: string;
}

dayjs.extend(relativeTime);

export const Notifications: React.FC = () => {
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [popoverWidth, setPopoverWidth] = useState<string>("350px");

    useEffect(() => {
        fetchNotifications();
    }, []);

    useEffect(() => {
        // Update popover width based on screen size
        const updatePopoverWidth = () => {
            if (window.innerWidth <= 480) {
                setPopoverWidth("300px"); // Adjust width for small screens
            } else {
                setPopoverWidth("350px"); // Default width
            }
        };

        // Add event listener for window resize
        window.addEventListener("resize", updatePopoverWidth);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener("resize", updatePopoverWidth);
        };
    }, []);

    const fetchNotifications = async () => {
        try {
            const token = localStorage.getItem("wafy-access-token");
            const response = await axios.get(`${API_BASE_URL}/user/api/v1/fetchNotifications`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            const data = response.data;
            setNotifications(data); // Set fetched notifications
        } catch (error) {
            console.error("Error fetching notifications:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const content = (
        <Space direction="vertical" split={<Divider style={{ margin: 0 }} />}>
            {notifications.map((notification) => (
                <Space key={notification.id} style={{ marginLeft: '20px'}}>
                    <Space direction="vertical" size={0}>
                        <Typography>{notification.message}</Typography>
                        <Text size="xs" type="secondary">
                            {dayjs(notification.created_at).fromNow()}
                        </Text>
                    </Space>
                </Space>
            ))}
        </Space>
    );

    const loadingContent = (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 20,
            }}
        >
            <Spin />
        </div>
    );

    return (
        <AntdPopover
            placement="bottomRight"
            content={isLoading ? loadingContent : content}
            trigger="click"
            overlayStyle={{ width: popoverWidth }}
        >
            <Badge dot>
                <Button
                    shape="circle"
                    icon={<BellOutlined />}
                    style={{ border: 0 }}
                />
            </Badge>
        </AntdPopover>
    );
};
