import React, {useEffect} from 'react';
import { GoogleOutlined } from "@ant-design/icons";
import wafy from '../../assets/webaccessify-logo.png'
import {
  LoginPageProps,
  LoginFormTypes,
  useLink,
  useRouterType,
  useActiveAuthProvider,
} from "@refinedev/core";
import {
  Row,
  Col,
  Layout,
  Card,
  Typography,
  Form,
  Input,
  Button,
  Checkbox,
  CardProps,
  LayoutProps,
  Divider,
  FormProps,
  theme,
} from "antd";
import { useLogin, useTranslate, useRouterContext } from "@refinedev/core";

import {
  bodyStyles,
  containerStyles,
  headStyles,
  layoutStyles,
  titleStyles,
} from "styles";

const { Text, Title } = Typography;
const { useToken } = theme;

type LoginProps = LoginPageProps<LayoutProps, CardProps, FormProps>;

export const Login: React.FC<LoginProps> = ({
  providers,
  registerLink,
  forgotPasswordLink,
  rememberMe,
  contentProps,
  wrapperProps,
  renderContent,
  formProps,
  title,
  hideForm,
}) => {
  const { token } = useToken();
  const [form] = Form.useForm<LoginFormTypes>();
  const translate = useTranslate();
  const routerType = useRouterType();
  const Link = useLink();
  const { Link: LegacyLink } = useRouterContext();

  const ActiveLink = routerType === "legacy" ? LegacyLink : Link;

  const authProvider = useActiveAuthProvider();
  const { mutate: login, isLoading } = useLogin<LoginFormTypes>({
    v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
  });

  useEffect(() => {
    document.title = "Login | WebAccessify";
  }, []);

  const PageTitle =
    title === false ? null : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "32px",
          fontSize: "20px",
        }}
      >
        <img
          src={wafy}
          alt="WebAccessify Logo"
          style={{ height: '40px', width: '40px', marginRight: '8px' }}
        />
        <span style={{ ...titleStyles }}>
          WebAccessify
        </span>
      </div>

    );

  const CardTitle = (
    <Title
      level={3}
      style={{
        ...titleStyles,
      }}
    >
      {translate("pages.login.title", "Sign in to your account")}
    </Title>
  );

  // const renderProviders = () => {
  //   const socialProviders = [
  //     {
  //       name: "google",
  //       label: "Sign in with Google",
  //       icon: (
  //         <GoogleOutlined
  //           style={{
  //             fontSize: 24,
  //             lineHeight: 0,
  //             color: token.colorPrimary,
  //           }}
  //         />
  //       ),
  //     },
  //   ];

  //   const allProviders = [...socialProviders, ...(providers || [])];

  //   if (allProviders.length > 0) {
  //     return (
  //       <>
  //         {!hideForm && (
  //           <Divider>
  //             <Text
  //               style={{
  //                 color: token.colorTextLabel,
  //               }}
  //             >
  //               {translate("pages.login.divider", "or")}
  //             </Text>
  //           </Divider>
  //         )}
  //         {allProviders.map((provider) => (
  //           <Button
  //             key={provider.name}
  //             size="middle"
  //             type="default"
  //             block
  //             icon={provider.icon}
  //             style={{
  //               display: "flex",
  //               justifyContent: "center",
  //               alignItems: "center",
  //               width: "100%",
  //               marginBottom: "8px",
  //             }}
  //             onClick={() =>
  //               login({
  //                 providerName: provider.name,
  //               })
  //             }
  //           >
  //             {provider.label}
  //           </Button>
  //         ))}

  //       </>
  //     );
  //   }

  //   return null;
  // };

  const CardContent = (
    <Card
      title={CardTitle}
      headStyle={headStyles}
      bodyStyle={bodyStyles}
      style={{
        ...containerStyles,
        backgroundColor: token.colorBgElevated,
      }}
      {...(contentProps ?? {})}
    >
      {!hideForm && (
        <Form<LoginFormTypes>
          layout="vertical"
          form={form}
          onFinish={(values) => login(values)}
          requiredMark={false}
          initialValues={{
            remember: false,
          }}
          {...formProps}
        >
          <Form.Item
            name="email"
            label={translate("pages.login.fields.email", "Email")}
            rules={[
              { required: true },
              {
                type: "email",
                message: translate(
                  "pages.login.errors.validEmail",
                  "Invalid email address",
                ),
              },
            ]}
          >
            <Input
              size="middle"
            />
          </Form.Item>
          <Form.Item
            name="password"
            label={translate(
              "pages.login.fields.password",
              "Password",
            )}
            rules={[{ required: true }]}
          >
            <Input
              type="password"
              autoComplete="current-password"
              size="middle"
            />
          </Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "24px",
            }}
          >
            {rememberMe ?? (
              <Form.Item
                name="remember"
                valuePropName="checked"
                noStyle
              >
                <Checkbox
                  style={{
                    fontSize: "12px",
                  }}
                >
                  {translate("pages.login.buttons.rememberMe", "Remember me")}
                </Checkbox>
              </Form.Item>
            )}
            {forgotPasswordLink ?? (
              <ActiveLink
                style={{
                  color: token.colorPrimary,
                  fontSize: "12px",
                  marginLeft: "auto",
                }}
                to="/forgot-password"
              >
                {translate(
                  "pages.login.buttons.forgotPassword",
                  "Forgot password?",
                )}
              </ActiveLink>
            )}
          </div>
          {!hideForm && (
            <Form.Item>
              <Button
                type="primary"
                size="middle"
                htmlType="submit"
                loading={isLoading}
                style={{ backgroundColor: '#018e86' }}
                block
              >
                {translate("pages.login.signin", "Sign in")}
              </Button>
            </Form.Item>
          )}
        </Form>
      )}

      {registerLink ?? (
        <div
          style={{
            marginTop: hideForm ? 16 : 8,
          }}
        >
          <Text style={{ fontSize: 12 }}>
            {translate(
              "pages.login.buttons.noAccount",
              "Don’t have an account?",
            )}{" "}
            <ActiveLink
              to="/register"
              style={{
                fontWeight: "bold",
                color: token.colorPrimary,
              }}
            >
              {translate("pages.login.signup", "Sign up")}
            </ActiveLink>
          </Text>
        </div>
      )}
      {/* {renderProviders()} */}
    </Card>
  );

  return (
    <Layout style={layoutStyles} {...(wrapperProps ?? {})}>
      <Row
        justify="center"
        align={hideForm ? "top" : "middle"}
        style={{
          padding: "16px 0",
          minHeight: "100dvh",
          paddingTop: hideForm ? "15dvh" : "16px",
        }}
      >
        <Col xs={22}>
          {renderContent ? (
            renderContent(CardContent, PageTitle)
          ) : (
            <>
              {PageTitle}
              {CardContent}
            </>
          )}
        </Col>
      </Row>
    </Layout>
  );
}
