import React, { useState, useEffect } from 'react';
import wafy from '../../assets/webaccessify-logo.png';
import { useNavigate } from 'react-router-dom';
import {
    ForgotPasswordPageProps,
    useRouterType,
    useLink,
} from '@refinedev/core';
import {
    Row,
    Col,
    Layout,
    Card,
    Typography,
    Form,
    Input,
    Button,
    LayoutProps,
    CardProps,
    FormProps,
    theme,
    message,
} from 'antd';
import {
    useTranslate,
    useRouterContext,
    useForgotPassword,
} from '@refinedev/core';

import {
    layoutStyles,
    containerStyles,
    titleStyles,
    headStyles,
    bodyStyles,
} from 'styles';
import config from "config/config";

// using Forget Password Props for OTP
type OtpVerificationForm = ForgotPasswordPageProps<
    LayoutProps,
    CardProps,
    FormProps
>;

interface ForgotPasswordFormTypes {
    email?: string;
    otp?: string
}

const { Text, Title } = Typography;
const { useToken } = theme;
const API_BASE_URL = config.API_BASE_URL;
export const OtpVerificationForm: React.FC<OtpVerificationForm> = ({
    wrapperProps,
    contentProps,
    renderContent,
    formProps,
    title,
}) => {
    const navigate = useNavigate();
    const { token } = useToken();
    const [form] = Form.useForm<ForgotPasswordFormTypes>();
    const translate = useTranslate();
    const routerType = useRouterType();
    const Link = useLink();
    const { Link: LegacyLink } = useRouterContext();

    const { mutate: forgotPassword, isLoading } = useForgotPassword<
        ForgotPasswordFormTypes
    >();

    useEffect(() => {
        document.title = "Verify OTP | WebAccessify";
    }, []);

    const [seconds, setSeconds] = useState(300);
    const [inputDisabled, setInputDisabled] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds((prevSeconds) => {
                if (prevSeconds === 0) {
                    clearInterval(interval);
                    return prevSeconds;
                }
                return prevSeconds - 1;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, []);


    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const handleResendOTP = async () => {
        try {
            const email = localStorage.getItem('userEmail');
            const response = await fetch(
                `${API_BASE_URL}/user/api/v1/updateOtp`,
                {
                    method: 'PATCH',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ email }) 
                }
            );
    
            const data = await response.json();
    
            if (response.status === 200) {
                message.success(data.message);
                setSeconds(300);
    
                // Check if retries count exceeds the limit
                if (data.message.includes('Max retries limit reached')) {
                    setInputDisabled(true); // Disable the input field
                } else {
                    setInputDisabled(false); // Enable the input field
                    // Start the countdown timer
                    const interval = setInterval(() => {
                        setSeconds((prevSeconds) => {
                            if (prevSeconds === 0) {
                                clearInterval(interval);
                                return prevSeconds;
                            }
                            return prevSeconds - 1;
                        });
                    }, 1000);
                }
            }
        } catch (error) {
            message.error('Error occurred while sending OTP')
        }
    };
    
    const handleButtonClick = async () => {
        try {
            await form.validateFields();
            const { otp } = form.getFieldsValue();
            const email = localStorage.getItem('userEmail')
            const origin = localStorage.getItem("otp_origin")
            const response = await fetch(
                `${API_BASE_URL}/user/api/v1/verifyOtp?email=${email}&otp=${otp}&origin=${origin}`,
                {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                }
            );

            const data = await response.json();

            if (response.status === 200) {
                message.success(data.message)
                const origin = localStorage.getItem("otp_origin")
                if (origin === "signup") {
                    navigate('/login');
                } else {
                    navigate('/update-password');
                }
                
            } else if (response.status === 400 || response.status === 404) {
                message.error(data.message);
                if (data.message.includes('Max retries limit reached')) {
                    setInputDisabled(true);
                }
            }
        } catch (error) {
            message.error('Error occurred while verifying OTP');
        }
    };

    const PageTitle =
        title === false ? null : (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '32px',
                    fontSize: '20px',
                }}
            >
                <img
                    src={wafy}
                    alt="WebAccessify Logo"
                    style={{ height: '40px', width: '40px', marginRight: '8px' }}
                />
                <span style={{ ...titleStyles }}>WebAccessify</span>
            </div>
        );

    const CardTitle = (
        <Title level={3} style={{ ...titleStyles }}>
            {translate('pages.OtpVerificationForm.title', 'OTP Verification')}
        </Title>
    );

    const CardContent = (
        <Card
            title={CardTitle}
            headStyle={headStyles}
            bodyStyle={bodyStyles}
            style={{
                ...containerStyles,
                backgroundColor: token.colorBgElevated,
            }}
            {...(contentProps ?? {})}
        >
            <Form<ForgotPasswordFormTypes>
                layout="vertical"
                form={form}
                requiredMark={false}
                {...formProps}
            >
                <Form.Item
                    name="otp"
                    label={translate(
                        "pages.OtpVerificationForm.fields.otp",
                        "Enter OTP",
                    )}
                >
                    <Input
                        size="middle"
                        disabled={inputDisabled}
                    />
                </Form.Item>
                <Row justify="space-between" align="middle">
                    <Col>
                        <Text>
                            OTP Expired in ({minutes}:{remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds})
                            <Button
                                type="link"
                                onClick={handleResendOTP}
                                disabled={seconds !== 0}
                            >
                                Resend
                            </Button>
                        </Text>
                    </Col>
                </Row>
                <Form.Item
                    style={{
                        marginTop: "24px",
                        marginBottom: 0,
                    }}
                >
                    <Button
                        type="primary"
                        size="middle"
                        htmlType="submit"
                        loading={isLoading}
                        style={{
                            backgroundColor: token.colorPrimary,
                        }}
                        block
                        onClick={handleButtonClick}
                    >
                        {translate(
                            "pages.OtpVerificationForm.buttons.submit",
                            "Submit",
                        )}
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );

    return (
        <Layout style={layoutStyles} {...(wrapperProps ?? {})}>
            <Row
                justify="center"
                align="middle"
                style={{
                    padding: '16px 0',
                    minHeight: '100dvh',
                }}
            >
                <Col xs={22}>
                    {renderContent ? (
                        renderContent(CardContent, PageTitle)
                    ) : (
                        <>
                            {PageTitle}
                            {CardContent}
                        </>
                    )}
                </Col>
            </Row>
        </Layout>
    );
};
