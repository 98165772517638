export const getNameInitials = (name: string, count = 2) => {
    const nameParts = name.split(" ");
    let initials = "";

    if (nameParts.length === 1) {
        initials += nameParts[0][0]
    } else {
        initials += nameParts[0][0] + nameParts[nameParts.length - 1][0]
    }

    const filtered = initials.replace(/[^a-zA-Z]/g, "");
    return filtered.slice(0, count).toUpperCase();
};
